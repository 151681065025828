import React, { useEffect, useRef, useState } from 'react';
import { useTransition } from '@/hooks/useTransition';
import classNames from '@/utils/className';

export function ProgressBar() {
  const [progress, setProgress] = useState(30);
  const { isTransitioning } = useTransition();
  const counter = useRef(0);

  const isMounted = isTransitioning || progress === 100 ? 'bg-mglBlue400 opacity-100' : 'bg-transparent opacity-0';

  useEffect(() => {
    if (!isTransitioning) {
      setProgress(100);
      setTimeout(() => {
        setProgress(30);
      }, 1000);
    } else {
      const changeProgress = async () => {
        setTimeout(() => {
          if (progress >= 80 && progress < 90) {
            setProgress(progress + 2);
          }
          if (progress >= 30 && progress < 80) {
            setProgress(progress + 10);
          }
        }, 1000);
        counter.current += 1;
      };
      changeProgress();
    }
  }, [counter.current, isTransitioning]);

  return (
    <div
      className={classNames('fixed left-0 top-0 z-[9999] h-1 transition-all duration-300 ease-ease', isMounted)}
      style={{ width: `${progress}%` }}
      role="progressbar"
      aria-label="Barra de carregamento"
      title="Barra de carregamento"
    />
  );
}

const ProgressBarMemo = React.memo(ProgressBar, () => true);

export default ProgressBarMemo;
