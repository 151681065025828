export const mglGray = {
  mglGray50: '#fafafa',
  mglGray100: '#f5f5f5',
  mglGray200: '#e5e5e5',
  mglGray300: '#d4d4d4',
  mglGray400: '#a3a3a3',
  mglGray500: '#737373',
  mglGray600: '#525252',
  mglGray700: '#404040',
  mglGray800: '#262626',
  mglGray900: '#171717',
} as const;

export const mglBlue = {
  mglBlue50: '#E5F3FF',
  mglBlue100: '#B3DBFF',
  mglBlue200: '#80C2FF',
  mglBlue300: '#339EFF',
  mglBlue400: '#0086ff',
  mglBlue500: '#0079E6',
  mglBlue600: '#006BCC',
  mglBlue700: '#005099',
  mglBlue800: '#003666',
  mglBlue900: '#001b33',
} as const;

export const mglYellow = {
  mglYellow50: '#fffae2',
  mglYellow100: '#fff1af',
  mglYellow200: '#ffe87c',
  mglYellow300: '#ffdb30',
  mglYellow400: '#fcd000',
  mglYellow500: '#fcd000',
  mglYellow600: '#c9a600',
  mglYellow700: '#967c00',
  mglYellow800: '#635200',
  mglYellow900: '#302800',
} as const;

export const mglOrange = {
  mglOrange50: '#fff3e5',
  mglOrange100: '#ffdcb3',
  mglOrange200: '#ffc480',
  mglOrange300: '#ffa133',
  mglOrange400: '#ff8a00',
  mglOrange500: '#E67C00',
  mglOrange600: '#CC6E00',
  mglOrange700: '#995300',
  mglOrange800: '#663700',
  mglOrange900: '#331C00',
} as const;

export const mglRed = {
  mglRed50: '#fff1f2',
  mglRed100: '#ffd7db',
  mglRed200: '#ffa4ad',
  mglRed300: '#ff5868',
  mglRed400: '#ff253a',
  mglRed500: '#ff0b23',
  mglRed600: '#f10017',
  mglRed700: '#D70015',
  mglRed800: '#BE0012',
  mglRed900: '#A40010',
} as const;

export const mglPink = {
  mglPink50: '#ffe9f6',
  mglPink100: '#ffd0ea',
  mglPink200: '#FFB6DF',
  mglPink300: '#ff83c9',
  mglPink400: '#FF6ABE',
  mglPink500: '#ff1d9d',
  mglPink600: '#ff37a8',
  mglPink700: '#ff0491',
  mglPink800: '#d00076',
  mglPink900: '#b60067',
} as const;

export const mglPurple = {
  mglPurple50: '#F0C7FF',
  mglPurple100: '#E294FF',
  mglPurple200: '#D461FF',
  mglPurple300: '#C015FF',
  mglPurple400: '#A400E1',
  mglPurple500: '#9100c7',
  mglPurple600: '#7f00ae',
  mglPurple700: '#6c0094',
  mglPurple800: '#5a007b',
  mglPurple900: '#340048',
} as const;

export const mglGreen = {
  mglGreen50: '#BCFFBE',
  mglGreen100: '#89FF8C',
  mglGreen200: '#0AFF0F',
  mglGreen300: '#00D604',
  mglGreen400: '#00d604',
  mglGreen500: '#00BC04',
  mglGreen600: '#00a303',
  mglGreen700: '#007002',
  mglGreen800: '#003d01',
  mglGreen900: '#000a00',
} as const;

export const kabum = {
  kabumBlue: '#055EB2',
  kabumOrange: '#F74F12',
} as const;

export const eletro = {
  eletroGreen: '#2EC900',
  eletroBlack: '#1C1C1C',
} as const;

export const ctauto = {
  ctautoRed: '#E0140F',
  ctautoBlack: '#1C1C1C',
};
