import { ComponentPropsWithoutRef } from 'react';

export const VoteButton = ({ children, ...rest }: ComponentPropsWithoutRef<'a'>) => {
  return (
    <a
      className="ml-4 inline-block min-h-[30px] w-full scale-100 rounded-[18px] bg-black p-0 px-4 text-center text-sm uppercase leading-5 tracking-[0.04rem] text-mglGray50 no-underline shadow-[0_0_0_0_rgba(0,_0,_0,_1)] transition-all duration-200 ease-ease tablet:p-[initial] desktop:w-[initial] [&_strong]:flex [&_strong]:justify-center [&_strong]:p-[7px] [&_strong]:text-center [&_strong]:text-sm [&_strong]:font-medium [&_strong]:uppercase [&_strong]:leading-5 [&_strong]:tracking-[0.04rem] [&_strong]:no-underline"
      {...rest}>
      {children}
    </a>
  );
};
