import { Fragment } from 'react';
import { dateCheck } from '@utils/cookies';
import * as S from './styles';
import { mglGray } from '@/styles/designSystemColors';

const blackFridayColors = {
  yellow: '#FFF000',
  black: '#050515',
  white: mglGray.mglGray50,
};

const bannerData = [
  {
    name: 'agenda-bf2022',
    startDate: '11/22/2022 19:15',
    endDate: '11/23/2022 23:59',
    link: {
      url: 'https://canalte.ch/agendabf',
    },
    color: {
      barColors: blackFridayColors.yellow,
      textColor: blackFridayColors.black,
      buttonBackground: blackFridayColors.black,
      textButtonColor: blackFridayColors.white,
      textBackground: blackFridayColors.black,
    },
    texts: {
      md: 'NÃO PERCA A BLACK FRIDAY 2022 DO CANALTECH!',
      sm: 'NÃO PERCA: BLACK FRIDAY CANALTECH 2022!',
      xs: 'BLACK FRIDAY CANALTECH!',
      buttonText: 'ADICIONAR NA AGENDA',
    },
    closedByUser: true,
  },
  {
    name: 'hoje-bf2022',
    startDate: '11-24-2022 00:00',
    endDate: '11-24-2022 18:30',
    link: {
      url: 'https://canalte.ch/agendabf',
    },
    color: {
      barColors: blackFridayColors.yellow,
      textColor: blackFridayColors.black,
      buttonBackground: blackFridayColors.black,
      textButtonColor: blackFridayColors.white,
      textBackground: blackFridayColors.black,
    },
    texts: {
      md: 'É HOJE, NÃO PERCA A NOSSA LIVE DA BLACK FRIDAY!',
      sm: 'HOJE: BLACK FRIDAY CANALTECH 2022!',
      xs: 'HOJE: BLACK FRIDAY CANALTECH!',
      buttonText: 'ADICIONAR NA AGENDA',
      offeredBy: '',
    },
    closedByUser: true,
  },
  {
    name: 'live-bf2023',
    startDate: '11/23/2023 19:15',
    endDate: '11/24/2023 03:00',
    link: {
      url: 'https://ofertas.canaltech.com.br/live-black-friday-2023/?utm_source=canaltech&utm_medium=site-barra&utm_campaign=bf2023',
    },
    color: {
      barColors: blackFridayColors.yellow,
      textColor: blackFridayColors.black,
      buttonBackground: blackFridayColors.black,
      textButtonColor: blackFridayColors.white,
      textBackground: blackFridayColors.black,
    },
    texts: {
      md: 'ESTAMOS AO VIVO: LIVE BLACK FRIDAY CANALTECH!',
      sm: 'AO VIVO: LIVE BLACK FRIDAY CANALTECH!',
      xs: 'AO VIVO: LIVE CT BLACK FRIDAY!',
      buttonText: 'ASSISTIR AGORA',
    },
    closedByUser: true,
  },

  {
    name: 'hoje-bf2022-25',
    startDate: '11/25/2022 02:15',
    endDate: '11/25/2022 09:00',
    link: {
      url: 'https://canalte.ch/agendabf-25',
    },
    color: {
      barColors: '#6FFF00',
      textColor: '#050515',
      buttonBackground: blackFridayColors.black,
      textButtonColor: blackFridayColors.white,
      textBackground: blackFridayColors.black,
    },
    texts: {
      md: 'HOJE TEM, NÃO PERCA A NOSSA 2ª LIVE DA BLACK FRIDAY!',
      sm: 'HOJE: 2ª LIVE BLACK FRIDAY CANALTECH!',
      xs: 'HOJE: 2ª LIVE DA BLACK FRIDAY!',
      buttonText: 'ADICIONAR NA AGENDA',
    },
    closedByUser: true,
  },

  {
    name: 'live-bf2023-24',
    startDate: '11/24/2023 10:30',
    endDate: '11/24/2023 15:00',
    link: {
      url: 'https://ofertas.canaltech.com.br/live-black-friday-2023/?utm_source=canaltech&utm_medium=site-barra&utm_campaign=bf2023-24',
    },
    color: {
      barColors: '#6FFF00',
      textColor: '#050515',
      buttonBackground: blackFridayColors.black,
      textButtonColor: blackFridayColors.white,
      textBackground: blackFridayColors.black,
    },
    texts: {
      md: 'ESTAMOS AO VIVO: LIVE BLACK FRIDAY CANALTECH!',
      sm: 'AO VIVO: LIVE BLACK FRIDAY CANALTECH!',
      xs: 'AO VIVO: LIVE CT BLACK FRIDAY!',
      buttonText: 'ASSISTIR AGORA',
    },
    closedByUser: true,
  },

  {
    name: 'acabou-bf2022',
    startDate: '11/25/2022 12:45',
    endDate: '11/25/2022 23:59',
    link: {
      url: 'https://ofertas.canaltech.com.br/top/?utm_source=canaltech&utm_medium=site-barra&utm_campaign=bf2022',
    },
    color: {
      barColors: '#FD00FF',
      textColor: '#050515',
      buttonBackground: blackFridayColors.black,
      textButtonColor: blackFridayColors.white,
      textBackground: blackFridayColors.black,
    },
    texts: {
      md: 'CONFIRA AS MELHORES OFERTAS DESTA BLACK FRIDAY',
      sm: 'AS MELHORES OFERTAS DESTA BLACK FRIDAY',
      xs: 'AS MELHORES OFERTAS DESTA BLACK',
      buttonText: 'VER OFERTAS',
    },
    closedByUser: true,
  },
  {
    name: 'premio-ct-2023',
    startDate: '12/11/2023 12:45',
    endDate: '01/31/2024 23:59',
    link: {
      url: 'https://premio.canaltech.com.br/2023/?utm_source=canaltech&utm_medium=site-barra&utm_campaign=premio-canaltech',
    },
    color: {
      barColors: 'linear-gradient(224deg, #a92fd8, #20bcfe)',
      textColor: mglGray.mglGray50,
      buttonBackground: mglGray.mglGray900,
      textButtonColor: mglGray.mglGray50,
      textBackground: mglGray.mglGray50,
    },
    emphasis: {
      md: 'PRÊMIO CANALTECH: ',
      sm: 'PRÊMIO CANALTECH: Participe e Vote!',
      xs: 'Vote no Prêmio Canaltech!',
    },
    texts: {
      md: 'escolha os melhores da tecnologia de 2023!',
      sm: '',
      xs: '',
      buttonText: 'VOTAR AGORA!',
    },
  },
  {
    name: 'premio-ct-2023-resultado-sorteio',
    startDate: '02/19/2024 12:30',
    endDate: '03/01/2024 23:59',
    link: {
      url: 'https://premio.canaltech.com.br/2023/?utm_source=canaltech&utm_medium=site-barra&utm_campaign=sorteio-premio-canaltech',
    },
    color: {
      barColors: 'linear-gradient(224deg, #20bcfe, #a92fd8)',
      textColor: mglGray.mglGray50,
      buttonBackground: mglGray.mglGray900,
      textButtonColor: mglGray.mglGray50,
      textBackground: mglGray.mglGray50,
    },
    emphasis: {
      md: '',
      sm: 'PRÊMIO CT! Resultado do Sorteio',
      xs: 'Ver sorteados do Prêmio',
    },
    texts: {
      md: 'SAIU O RESULTADO DO SORTEIO DO PRÊMIO CANALTECH!',
      sm: '',
      xs: '',
      buttonText: 'CONFERIR',
    },
  },
];

export default function Banner() {
  return (
    <>
      {bannerData.map(banner => {
        const bannerIsInactive = !dateCheck(banner.startDate, banner.endDate, new Date());
        return (
          <Fragment key={banner.name}>
            {!bannerIsInactive && (
              <section
                style={{ background: banner.color.barColors }}
                className="fixed left-0 top-0 z-[988] h-[50px] min-h-[50px] w-full overflow-hidden p-0 pl-0 text-mglGray50 tablet:pl-[60px] [&+div+div]:mt-[50px] [&+div]:mt-[50px] [&_svg]:ml-[10px] [&_svg]:cursor-pointer">
                <div className="mx-auto my-0 flex w-full max-w-full basis-full items-center justify-center px-[0.625rem] tablet:max-w-[83.3333%] tablet:basis-[83.3333%] desktop:px-0">
                  <div className="hidden items-center px-0 py-2 desktop:flex">
                    <strong style={{ color: banner.color.textBackground }} className="hidden items-center whitespace-pre-wrap font-medium desktop:flex">
                      <strong>{banner.emphasis.md}</strong> {banner.texts.md}
                    </strong>
                    <S.VoteButton href={banner.link.url} target="_blank" title={banner.texts.md}>
                      <strong style={{ color: banner.color.textButtonColor }}>{banner.texts.buttonText}</strong>
                    </S.VoteButton>
                  </div>
                  <div className="hidden w-full items-center py-2 tablet:flex desktop:hidden">
                    <S.VoteButton href={banner.link.url} target="_blank" title={banner.texts.sm}>
                      <strong style={{ color: banner.color.textButtonColor }}>{banner.emphasis.sm}</strong>
                    </S.VoteButton>
                  </div>
                  <div className="flex w-full items-center px-0 py-2 tablet:hidden">
                    <S.VoteButton href={banner.link?.url} target="_blank" title={banner.texts?.xs}>
                      <strong style={{ color: banner.color.textButtonColor }}>{banner.emphasis?.xs}</strong>
                    </S.VoteButton>
                  </div>
                </div>
              </section>
            )}
          </Fragment>
        );
      })}
    </>
  );
}
