/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable camelcase */

export function setCookie(name, value, exdays, path, global) {
  value = escape(value);

  if (exdays) {
    const exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    value += `; expires=${exdate.toUTCString()}`;
  }

  if (global) {
    value += `; domain=.${window.location.hostname.replace(/^([^.]*\.)*([^.]+)((\.[^.]+){2})$/, '$2$3')}`;
  }

  value += `; path=${path || '/'}`;

  document.cookie = `${name} = ${value}`;
}

export function getCookie(c_name) {
  let i;
  let x;
  let y;
  const ARRcookies = document.cookie.split(';');
  for (i = 0; i < ARRcookies.length; i++) {
    x = ARRcookies[i].substr(0, ARRcookies[i].indexOf('='));
    y = ARRcookies[i].substr(ARRcookies[i].indexOf('=') + 1);
    x = x.replace(/^\s+|\s+$/g, '');
    if (x == c_name) {
      return unescape(y);
    }
  }
  return false;
}

export const dateCheck = (from, to, check) => {
  const fDate = Date.parse(from);
  const lDate = Date.parse(to);
  const cDate = Date.parse(check);

  if (cDate <= lDate && cDate >= fDate) return true;
  return false;
};
