import { ReactNode } from 'react';
import classNames from '@/utils/className';

interface GlobalContainerProps {
  children: ReactNode | ReactNode[];
  whiteBg?: boolean;
  center?: boolean;
  useNavbar?: boolean;
}

export default function GlobalContainer({ children, whiteBg, center, useNavbar = true }: GlobalContainerProps) {
  const variants = {
    whiteBg: {
      true: 'whiteBg [&.whiteBg]:bg-mglGray50',
      false: '',
    },
    center: {
      true: 'center flex flex-col justify-center items-center [&.center]:h-screen [&.center]:py-10 [&.center]:px-0',
      false: '',
    },
  } as const;
  return (
    <div
      className={classNames(
        'h-full w-full bg-mglGray100 ',
        useNavbar && 'pl-0 pt-[60px] tablet:pl-[60px] tablet:pt-0',
        whiteBg != undefined && variants.whiteBg[whiteBg.toString()],
        center != undefined && variants.center[center.toString()]
      )}>
      {children}
    </div>
  );
}
