
export default function useScriptsToRoute(currentRoute: string) {
  let useMgid = true;
  let useGpt = true;
  let useMarfeel = true;
  let useGtm = true;
  let useNavbar = true;
  let useComscore = true;
  let useFullScreen = false;
  let specifyId = undefined;

  switch (currentRoute) {
      case '/guia-de-compras':
        useMgid = false;
        useGpt = false;
        useMarfeel = false;
        useComscore = false;

        useNavbar = false;
        useFullScreen = true;

        specifyId = 'GTM-PT37RWSC';
        break;
      case '/live':
        useMgid = false;
        useGpt = false;
        useMarfeel = false;
        useComscore = false;

        useNavbar = false;
        useFullScreen = true;
        break;
      default:
        break;
    }

  return {
    useMgid: useMgid,
    useGpt: useGpt,
    useMarfeel: useMarfeel,
    useGtm: useGtm,
    specifyId: specifyId,
    useNavbar: useNavbar,
    useComscore: useComscore,
    useFullScreen: useFullScreen,
  };
}
